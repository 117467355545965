import React from "react"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

interface Props {
  title?: string
  description?: string
  image?: string
}

const SEO: React.FC<Props> = ({ description, image, title }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTemplate
            description
            url: siteUrl
            twitterUsername
            author
            image
          }
        }
      }
    `
  )
  const seo = {
    title: title || site.siteMetadata.title,
    titleTemplate: site.siteMetadata.titleTemplate,
    description: description || site.siteMetadata.description,
    url: `${site.siteMetadata.url}${pathname}`,
    image: site.siteMetadata.image,
  }

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      title={seo.title}
      titleTemplate={seo.titleTemplate}
      htmlAttributes={{
        lang: "en-us",
      }}
    >
      <style>
        @import
        url('https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Lobster&family=Londrina+Outline&family=Cabin+Sketch:wght@700&display=swap');
      </style>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1349276647946543"
        crossorigin="anonymous"
      ></script>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  )
}

export default SEO
